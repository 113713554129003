import React, { FC, useContext } from "react";
import { Box, Flex, Heading, Text as ChakraText } from "@chakra-ui/react";
import { sc, useSitecoreContext } from "~/foundation/Jss";
import { GenericHeaderBase } from "~/foundation/SitecoreContent/generated-types";
import { LinkVariant } from "~/foundation/Theme/components/Link";
import { ColorThemeContext } from "~/foundation/Theme/ColorThemeContext";

export type GenericHeaderProps = GenericHeaderBase & {
	variant?: LinkVariant
}

export const GenericHeader: FC<GenericHeaderProps> = ({
	trumpet,
	headline,
	subHeadline,
	description,
	link,
	variant = "primary",
}) => {
	const { sitecoreContext } = useSitecoreContext();
	const [theme] = useContext(ColorThemeContext);
	const { colors } = theme;

	return (
		<>
			{(trumpet?.value || headline?.value || subHeadline?.value || description?.value || link?.value.href || sitecoreContext.pageEditing) &&
				<Flex maxW="contentWrapperWidth" pb={["8", null, "16"]} flexDir={["column", null, null, "row"]} gap={["0", null, null, "6"]}>
					<Flex basis={["100%", null, null, "50%"]} flexWrap="wrap" flexDir="column">
						{(trumpet?.value || sitecoreContext.pageEditing) &&
							<ChakraText variant="overline" mb="1.375rem">
								<sc.Text field={trumpet}/>
							</ChakraText>
						}
						{(headline?.value || sitecoreContext.pageEditing) &&
							<Heading as="h2" size="md" mb="1.375rem">
								<sc.Text field={headline} />
							</Heading>
						}
						{(subHeadline?.value || sitecoreContext.pageEditing) &&
							<ChakraText size="xs" mb="1.375rem" as={headline?.value ? "p" : "h2"}>
								<sc.Text field={subHeadline} />
							</ChakraText>
						}
						{(description?.value || sitecoreContext.pageEditing) &&
							<ChakraText as="span" size="body" mb="1.375rem">
								<sc.RichText field={description} />
							</ChakraText>
						}
					</Flex>
					{(link?.value.href || sitecoreContext.pageEditing) && (
						<Flex basis={["100%", null, null, "50%"]} alignItems="flex-end" justifyContent="flex-end">
							<Box mt={["2", null, "0"]} mb={["1.375rem", null, "0.5"]} textAlign="right">
								<sc.Link
									field={link}
									variant={variant}
									size={["md", null, null, "lg"]}
									color={colors.ribbon.linkColor} />
							</Box>
						</Flex>
					)}
				</Flex>
			}
		</>
	);
};